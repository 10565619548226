.list {
    display: none; /* Hide in mobile */
    font-size: 12px;
    padding: 10px;
    background-color: rgba(255, 255, 255);
    color: #777;
    overflow-y: auto;
}
.list ul {
    list-style-type: none;
}

.list ul li:last-child {
    margin-bottom: 5px;;
}

.list li {
    padding-left: 10px;
    max-width: 200px;
    text-overflow: ellipsis;
}

.list h4 {
    color: #32496e;
}

/* Only show in wide screens */
@media screen and (min-width: 768px) {
    .list {
      display: block;
    }
  }
  