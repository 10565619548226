.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 150px;
  margin: 12px 16px 8px;
}

.userContainer {
  display: flex;
  margin: 12px 16px;
}

.userText {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  font-size: small;
}

.userPicture {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.userName {
  font-weight: 500;
  font-size: 0.9rem;
}

.logout {
  margin-top: 8px;
  font-size: small;
  text-decoration: none;
  color: white;
}

.logout:hover {
  text-decoration: underline;
}
