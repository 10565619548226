.button {
  color: #fff;
  border-color: #fff;
  padding: 12px 16px;
  background: none;
  border: 2px solid #fff;
  font-family: 'Metropolis', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.button:hover {
  background-color: white;
  color: #008ad8;
}

.solid {
  background-color: #008ad8;
  border-color: #008ad8;
  color: #fff;
}

.solid:hover {
  background-color: #071d49;
  border-color: #071d49;
  color: #fff;
}

.small {
  padding: 4px 8px;
  font-size: small;
}
