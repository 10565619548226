.container {
  height: 100vh;
  background-image: url('../assets/img/kelsus-home-bg.jpg');
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 300px;
  margin-left: 26px;
  margin-bottom: 24px;
}
