@font-face {
  font-display: swap;
  font-family: 'Metropolis';
  src: url('./assets/fonts/metropolis-regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-display: swap;
  font-family: 'Metropolis';
  src: url('./assets/fonts/metropolis-medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: 'Metropolis';
  src: url('./assets/fonts/metropolis-bold.woff2') format('woff2');
  font-weight: 600;
}

body {
  margin: 0;
  font-family: 'Metropolis', sans-serif;
  color: white;
}

* {
  margin: 0;
  padding: 0;
}

/* Mapbox Styles */

.mapboxgl-popup-content {
  font-family: 'Metropolis', sans-serif;
  background: rgba(7, 29, 73, 0.8);
  border-radius: 8px;
  box-shadow: none;
  padding: 12px 12px 8px 12px;
}

.mapboxgl-popup-content p {
  margin: 0;
  padding: 0;
}

.mapboxgl-popup-content .place-title {
  font-weight: bold;
  font-size: medium;
  margin-bottom: 4px;
  line-height: 1;
  padding-bottom: 4px;
}

.mapboxgl-popup-content .place-title small {
  font-weight: normal;
  font-size: x-small;
}

.mapboxgl-popup-content .team-member {
  font-size: small;
}

.mapboxgl-popup-close-button {
  padding: 4px 6px;
  color: white;
}

.mapboxgl-popup-close-button:hover {
  background-color: transparent;
  font-weight: bold;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: rgba(7, 29, 73, 0.8);
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: rgba(7, 29, 73, 0.8);
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: rgba(7, 29, 73, 0.8);
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: rgba(7, 29, 73, 0.8);
}
