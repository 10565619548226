.panel {
  color: #fff;
  background: rgba(7, 29, 73, 0.8);
  font-family: 'Metropolis', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.round {
  border-radius: 8px;
}

